import TermModel from '@/views/service/term/model/TermModel';
import router from '@/router';
import store from '@lemontree-ai/lemontree-admin-common-front/store/index';

import Diff from 'vue-jsdiff';
import { calcDate, convertDateFormat, getDayToWeek, timeToKorean } from '@lemontree-ai/lemontree-admin-common-front/utils/dateUtils'
import { convertIdToText } from '@lemontree-ai/lemontree-admin-common-front/utils/selectDataUtils'

export default class TermDetailViewModel {
  constructor() {
    this.model = {};
    this.isModify = false;
    this.isDiffMode = false;
    this.diffData = '';
    this.diffNpmData = '';
    this.diffCodeData = '';
    this.lastDepolyTermContent = '';
    this.copyDataList = [];
    this.copyTermId = ''
  }

  init(id, type){
    this.model = new TermModel();
    this.model.type = type
    if(id){
      this.getTermDetail(id, type)
    }else{
      this.setDeployAtTomorrow();
    }
    this.getCopyData();
  }

  setDeployAtTomorrow(){
    this.model.deploy_at = `${calcDate(new Date(), 1, 'day', 'YYYY-MM-DD')} 00:00:00`
  }

  onClickRow(){
    router.push({ name: 'APP_TERMS_DETAIL' });
  }

  onClickCopyTerm(){
    this.model.content = this.copyDataList.find(item => item.id === this.copyTermId).content;
    this.setDiffContent()
  }

  // 약관 비교
  onUpdateEditor(){
    // this.setDiffContent();
  }

  setDiffContent(){
    this.diffNpmData = this.diffCheck(this.lastDepolyTermContent.replace( /(<([^>]+)>)/gi, ''),this.model.content.replace( /(<([^>]+)>)/gi, ''));

    const old_str = this.lastDepolyTermContent.replace( /(<([^>]+)>)/gi, '');
    const new_str = this.model.content.replace( /(<([^>]+)>)/gi, '');
    const diffResult = this.diffCommon(old_str,new_str,'chars');
    // console.log(diffResult);
    this.diffData = this.setResultWithHighlight(diffResult,'chars')


    // const oldElement = document.getElementsByClassName('area_oldval');
    // const newElement = document.getElementsByClassName('area_newval');
    // this.diffHtml(oldElement, newElement)
  }

  diffHtml(oldElement, newElement){
    const oldData = Array.from(oldElement[0].children);
    const newData = Array.from(newElement[0].children);
    console.log(oldData);
    console.log(newData);
  }
  diffCommon(oldData, newData, diffType){
    if(!diffType){
      diffType = 'chars'
    }
    let joinType = '';
    switch(diffType){
      case 'chars':{
        joinType = '';
        break
      }
      case 'words':{
        joinType = ' ';
        break
      }
    }
    let oldArray = oldData.split(joinType);
    let newArray = newData.split(joinType);
    let checkOldIndex = 0;
    let checkNewIndex = 0;
    let result = [];
    let keepingSamedValue = '';
    let keepingAddedValue = '';
    let keepingRemovedValue = '';
    const longestLength = Math.max(oldArray.length, newArray.length);
    const isOldLong = oldArray.length >= newArray.length;
    const isNewLong = newArray.length >= oldArray.length;
    const _this = this;
    function returnResult(){
      if(keepingSamedValue){
        result.push(_this.getResultItem('samed',keepingSamedValue,result[result.length-1] ? result[result.length-1].id+1 : 0));
        keepingSamedValue = '';
      }
      if(keepingRemovedValue){
        result.push(_this.getResultItem('removed',keepingRemovedValue,result[result.length-1] ? result[result.length-1].id+1 : 0));
        keepingRemovedValue = '';
      }
      if(keepingAddedValue){
        result.push(_this.getResultItem('added',keepingAddedValue,result[result.length-1] ? result[result.length-1].id+1 : 0));
        keepingAddedValue = '';
      }
      // console.log('**result');
      // console.log(result);
      return result;
    }
    let i = 0;
    let maxLoop = longestLength;
    // console.log(oldArray.length,newArray.length);
    while(i < maxLoop){
      i ++
      const oldVal = oldArray[checkOldIndex];
      const newVal = newArray[checkNewIndex];
      if(!oldVal && !newVal) {
        return returnResult();
      };
      // console.log('for --------',longestLength-1,i, oldVal ,`(${checkOldIndex}) | `, newVal ,`(${checkNewIndex})`);
//A1BQD
//A0B(테스트)A1BQ
// -- 값 동일
      if(oldVal === newVal){
// console.log('값 동일');
// 🔙 before Save
        if(keepingRemovedValue){
          // console.log('keepingRemovedValue',keepingRemovedValue)
          if(keepingRemovedValue[0] === newVal){
            keepingRemovedValue = keepingRemovedValue.slice(1);
          }else{
            // result.push(this.getResultItem('removed',keepingRemovedValue,result[result.length-1] ? result[result.length-1].id+1 : 0));
            // keepingRemovedValue = '';
              keepingRemovedValue += joinType + oldVal;
          }
        }
        if(keepingAddedValue){
          // console.log('keepingAddedValue',keepingAddedValue)
          if(keepingAddedValue[0] === newVal){
            keepingAddedValue = keepingAddedValue.slice(1);
          }else{
            // result.push(this.getResultItem('added',keepingAddedValue,result[result.length-1] ? result[result.length-1].id+1 : 0));
            // keepingAddedValue = '';
              keepingAddedValue += joinType + newVal;
          }
        }
        // if(!keepingAddedValue && !keepingAddedValue){
          keepingSamedValue += joinType + newVal;
        // }
        // console.log('keepingSamedValue',keepingSamedValue)
// same keeping
        checkOldIndex ++
        checkNewIndex ++

// <<< 값 다름
      }else{
// 🔙 before Save
      if(keepingSamedValue){
        // console.log("%c" + `keepingSamedValue ${keepingSamedValue}`, "color:purple;font-size:16px")
        // console.log("%c" + `keepingRemovedValue ${keepingRemovedValue}`, "color:purple;font-size:16px")
        // console.log("%c" + `keepingAddedValue ${keepingAddedValue}`, "color:purple;font-size:16px")
        if(!keepingRemovedValue && !keepingAddedValue){
          result.push(this.getResultItem('samed',keepingSamedValue,result[result.length-1] ? result[result.length-1].id+1 : 0));
          keepingSamedValue = '';
        }
        if(keepingSamedValue != ' ' && keepingRemovedValue && keepingAddedValue && keepingRemovedValue.indexOf(keepingSamedValue) > -1 && keepingAddedValue.indexOf(keepingSamedValue) > -1){
          // console.log("%c" + `${keepingRemovedValue.indexOf(keepingSamedValue)}${keepingAddedValue.indexOf(keepingSamedValue)}`, "color:purple;font-size:16px");
          const removedIndex = keepingRemovedValue.indexOf(keepingSamedValue);
          const addedIndex = keepingAddedValue.indexOf(keepingSamedValue);
          const samedLength = keepingSamedValue.length;
          
          const beforeRemove =  keepingRemovedValue.slice(0,removedIndex);
          const afterRemove =  keepingRemovedValue.slice(removedIndex+samedLength,keepingRemovedValue.length);
          // console.log('beforeRemove',beforeRemove);
          // console.log('afterRemove',afterRemove);
          result.push(this.getResultItem('removed',beforeRemove,result[result.length-1] ? result[result.length-1].id+1 : 0));
          keepingRemovedValue = '';
          if(afterRemove != undefined){
            keepingRemovedValue = afterRemove;
          }
          
          const beforeAdded =  keepingAddedValue.slice(0,addedIndex);
          const afterAdded =  keepingAddedValue.slice(addedIndex+samedLength,keepingAddedValue.length);
          // console.log('beforeAdded',beforeAdded);
          // console.log('afterAdded',afterAdded);
          result.push(this.getResultItem('added',beforeAdded,result[result.length-1] ? result[result.length-1].id+1 : 0));
          keepingAddedValue = '';
          if(afterAdded != undefined){
            keepingAddedValue = afterAdded;
          }
          if(afterRemove != undefined || afterAdded != undefined){
            result.push(this.getResultItem('samed',keepingSamedValue,result[result.length-1] ? result[result.length-1].id+1 : 0));
            keepingSamedValue = '';
          }else{
            keepingSamedValue += joinType + newVal;
          }

        }
      }

// <<< oldVal && newVal
        // if(oldVal && newVal){
// // oldVal && newVal::v-deep

          // console.log(keepingRemovedValue);
          // console.log(keepingAddedValue);
          // console.log(keepingSamedValue);
          if(keepingRemovedValue && keepingRemovedValue.indexOf(newVal) > -1 && newVal != ' '){
            const correctIndex = keepingRemovedValue.indexOf(newVal);
            const correctBefore = keepingRemovedValue.slice(0,correctIndex);
            const correctAfter = keepingRemovedValue.slice(correctIndex+1,keepingRemovedValue.length);
            // console.log("%c" + `newVal in oldList / ${newVal} / ${correctIndex}`, "color:blue;font-size:16px")
            // console.log('correctBefore',correctBefore,`(${correctBefore.length})`)
            // console.log('correctAfter',correctAfter,`(${correctAfter.length})`)

            result.push(this.getResultItem('removed',correctBefore,result[result.length-1] ? result[result.length-1].id+1 : 0));
            result.push(this.getResultItem('added',keepingAddedValue,result[result.length-1] ? result[result.length-1].id+1 : 0));
            result.push(this.getResultItem('samed',newVal,result[result.length-1] ? result[result.length-1].id+1 : 0));
            keepingRemovedValue = "";
            // if(oldVal && correctAfter.length <= 0){
            //   keepingRemovedValue += joinType + oldVal;
            // }
            keepingAddedValue = '';
            checkNewIndex ++
            checkOldIndex -= correctAfter.length;
            const oldLeftLength = oldArray.length - checkOldIndex;
            // console.log("%c" + `maxLoop-check ${ oldLeftLength } ${ maxLoop - i}`, "color:lightgreen;font-size:14px")
            if(oldLeftLength > maxLoop - i){
              maxLoop = i + oldLeftLength;
              // console.log("%c" + `maxLoop${maxLoop}`, "color:green;font-size:20px")
            }
            // console.log('removed',keepingRemovedValue)
            // console.log('added',keepingAddedValue)
            // console.log('samed',keepingSamedValue)
          }
          else if(keepingAddedValue && keepingAddedValue.indexOf(oldVal) > -1 && oldVal != ' '){
            const correctIndex = keepingAddedValue.indexOf(oldVal);
            const correctBefore = keepingAddedValue.slice(0,correctIndex);
            const correctAfter = keepingAddedValue.slice(correctIndex+1,keepingAddedValue.length);
            // console.log("%c" + `oldVal in newList / ${oldVal} / ${correctIndex}`, "color:red;font-size:16px")
            // console.log('correctBefore',correctBefore,`(${correctBefore.length})`)
            // console.log('correctAfter',correctAfter,`(${correctAfter.length})`)

            result.push(this.getResultItem('removed',keepingRemovedValue,result[result.length-1] ? result[result.length-1].id+1 : 0));
            result.push(this.getResultItem('added',correctBefore,result[result.length-1] ? result[result.length-1].id+1 : 0));
            result.push(this.getResultItem('samed',oldVal,result[result.length-1] ? result[result.length-1].id+1 : 0));
            keepingAddedValue = "";
            // if(newVal && correctAfter.length <= 0){
            //   keepingAddedValue += joinType + newVal;
            // }
            keepingRemovedValue = '';
            checkOldIndex ++
            checkNewIndex -= correctAfter.length;
            const newLeftLength = newArray.length - checkNewIndex;
            // console.log("%c" + `maxLoop-check ${ newLeftLength } ${ maxLoop - i}`, "color:lightgreen;font-size:14px")
            if(newLeftLength > maxLoop - i){
              maxLoop = i + newLeftLength;
              // console.log("%c" + `maxLoop${maxLoop}`, "color:green;font-size:20px")
            }
            // console.log('removed',keepingRemovedValue)
            // console.log('added',keepingAddedValue)
            // console.log('samed',keepingSamedValue)
          }else{
// console.log('indexOf 없어');
// console.log(isOldLong , keepingRemovedValue , keepingRemovedValue.indexOf(newVal) > -1 , newVal != ' ');
// console.log(isNewLong , keepingAddedValue , keepingAddedValue.indexOf(oldVal) > -1 , oldVal != ' ');
            // if(keepingAddedValue.length > 2 && oldArray.slice(0,checkOldIndex).join(joinType).indexOf(keepingAddedValue.slice(-3)) > -1){
            //   // console.log("%c" + keepingAddedValue.slice(-3), "color:orange;font-size:20px");
            //   const correctVal = keepingAddedValue.slice(-3);
            //   const correctIndex = oldArray.join(joinType).indexOf(keepingAddedValue.slice(-3))
            //   // console.log(correctIndex);

            //   // console.log(i,'/',maxLoop);
            //   i = i - ( checkOldIndex - (correctIndex + 3) )
            //   // console.log(i,'/',maxLoop);

            //   const oldResult = result.filter(item => item.samed || item.removed).reverse();
            //   let sizeTotal = 0;
            //   let index = 0;
            //   // console.log(checkOldIndex - correctIndex);
            //   // console.log(result);
            //   // console.log(oldResult);
            //   while(sizeTotal < checkOldIndex - correctIndex - keepingRemovedValue.length){
            //     // console.log(sizeTotal , index, oldResult[index])
            //     sizeTotal += oldResult[index].size;
            //     index ++
            //   }
            //   const diffDeletedItem = sizeTotal - ( checkOldIndex - correctIndex );
            //   let deletedResultItem = oldResult[index-1]
            //   let deletedResultId = deletedResultItem.id;
            //   const diffDeletedItemType = deletedResultItem.samed ? 'samed' : 'removed'
            //   const diffDeletedItemValueBefore = deletedResultItem.value.slice(0,diffDeletedItem);
            //   // console.log('deletedResultItem',deletedResultItem);
            //   // console.log('diffDeletedItem',diffDeletedItem);
            //   const deletedIndex = result.findIndex(item => item.id === deletedResultId);
            //   const beforeResult = result.slice(0,deletedIndex);
            //   result = beforeResult;
            //   result.push(this.getResultItem(diffDeletedItemType,diffDeletedItemValueBefore,result[result.length-1] ? result[result.length-1].id+1 : 0))

            //   result.push(this.getResultItem('added',newArray.slice(correctIndex,checkNewIndex-3).join(joinType),result[result.length-1] ? result[result.length-1].id+1 : 0));
            //   result.push(this.getResultItem('samed',correctVal,result[result.length-1] ? result[result.length-1].id+1 : 0));
            //   keepingAddedValue = "";
            //   keepingRemovedValue = '';

            //   checkOldIndex = correctIndex + 3;
            // }else{
              if(oldVal != undefined){
                if(keepingRemovedValue.slice(-1) === oldVal && newVal === undefined){
                //   // keepingRemovedValue = keepingRemovedValue.slice(1);
                }else{
                  keepingRemovedValue += joinType + oldVal;
                }
              }else{
                checkOldIndex -= 1;
              }
              if(newVal != undefined){
                if(keepingAddedValue.slice(-1) === newVal && oldVal === undefined){
                  // keepingAddedValue = keepingAddedValue.slice(1);
                }else{
                  keepingAddedValue += joinType + newVal;
                }
              }else{
                checkNewIndex -= 1;
              }
              checkOldIndex ++
              checkNewIndex ++
            // }
            // console.log(checkOldIndex,oldArray[checkOldIndex]);
            // console.log(checkNewIndex,newArray[checkNewIndex]);
            // console.log(keepingRemovedValue);
            // console.log(keepingAddedValue);
          }
// 삭제
        // }
//         else if(oldVal){
// console.log('삭제');
//           keepingRemovedValue += joinType + oldVal;
//           checkOldIndex ++
//           checkNewIndex ++
// // 추가
//         }else if(newVal){
// console.log('추가');
//           keepingAddedValue += joinType + newVal;
//           checkOldIndex ++
//           checkNewIndex ++
//         }
//         else{
// console.log('기타');
//           keepingRemovedValue += joinType + oldVal;
//           keepingAddedValue += joinType + newVal;
//           checkOldIndex ++
//           checkNewIndex ++
//         }
      }
// 값 다름::v-deep
    }
    return returnResult();
  }
  getResultItem(type,value,id){
    let resultItem = {
      id: id,
      samed : false,
      added : false,
      removed : false,
      value : value,
      size: value.length
    };
    resultItem[type] = true;
    return resultItem;
  }
  setResultWithHighlight(result,diffType){
    let joinType = '';
    switch(diffType){
      case 'chars':{
        joinType = '';
        break
      }
      case 'words':{
        joinType = ' ';
        break
      }
    }
    return result.map(item => {
      if(item.removed === true){
        return `<span class="txt_diff txt_diff_remove">${item.value}</span>`
      }else if(item.added === true){
        return `<span class="txt_diff txt_diff_add">${item.value}</span>`
      }else{
        return item.value;
      }
    }).join(joinType)
  }


  diffCheck(oldVal,newVal,isDepth){
    let diffResult = Diff.diffChars(oldVal.replace( /(<([^>]+)>)/gi, ''), newVal);
    return diffResult.map(item => {
      // console.log('*item',item)
      const isTag = this.isTagWrap(item.value)
      if(item.removed === true){
        return `<span class="txt_diff txt_diff_remove">${item.value}</span>`
      }else if(item.added === true){
        if(isTag){
          const inTag = item.value.replace( /(<([^>]+)>)/gi, '');
          if(inTag.length > 0){
            const isTagResult = this.diffCheck(item.value,item.value,true);
            return isTagResult;
          }
          return item.value;
        }else{
          return `<span class="txt_diff txt_diff_add">${item.value}</span>`
        }
      }else{
        if(isDepth){
          return `<span class="txt_diff txt_diff_add">${item.value}</span>`
        }
        return item.value;
      }
    }).join('')
  }

  isTagWrap(value){
    return value.slice(0,1) === '<' && value.slice(-1) === '>';
  }

  onClickDelete(){
    const requestDelete = () => {
      router.push({ name: 'SERVICE_TERM_LIST', query: { 'type' : this.model.type } });
      store.dispatch('commonToast/fetchToastStart', `약관이 삭제되었습니다.`);
    }
    store.dispatch('commonAlert/fetchAlertStart', {
      alertMsg: `약관을 정말 삭제하시겠습니까?`,
      closeBtnText: '취소',
      compeleteBtnText: '확인',
      confirmCallBack: requestDelete,
    });
  }
  onClickCancel(){
    const requestDelete = () => {
      router.push({ name: 'SERVICE_TERM_LIST', query: { 'type' : this.model.type } });
      store.dispatch('commonToast/fetchToastStart', `배포예약이 취소되었습니다.`);
    }
    store.dispatch('commonAlert/fetchAlertStart', {
      alertMsg: `약관 배포예약을 취소하시겠습니까?`,
      closeBtnText: '취소',
      compeleteBtnText: '예약취소',
      confirmCallBack: requestDelete,
    });
  }
  
  onClickCompelete(){
    const compeleteOk = () => {
      let toastMessage = '';
      if(this.isModify){
        toastMessage = '수정';
        router.push({ name: 'SERVICE_TERMS_DETAIL', params: { type:this.model.type, id : this.model.id } });
      }else{
        toastMessage = '등록';
        router.push({ name: 'SERVICE_TERM_LIST', query: { 'type' : this.model.type } });
      }
      store.dispatch('commonToast/fetchToastStart', `약관 ${toastMessage}이 완료되었습니다.`);
    }
    store.dispatch('commonAlert/fetchAlertStart', {
      alertMsg: `<strong>새 버전을 배포예정일에 게시합니다</strong><ul><li>${convertDateFormat(this.model.deploy_at,'YYYY년 MM월 DD일')} (${getDayToWeek(this.model.deploy_at)}) ${timeToKorean(this.model.deploy_at)}에 게시됩니다.</li><li>기존 약관에서 변경된 경우,<br>사용자에게 반드시 고지해야 합니다.</li></ul>`,
      closeBtnText: '취소',
      compeleteBtnText: '예약취소',
      confirmCallBack: compeleteOk,
    });
  }
  getCopyData(){
    const resultDataList = {
      SERVICE:[
        {
          id:'5',
          content:'<h1>제1장 총칙</h1><h2>제1조 (목적)</h2><p>본 약관은 주식회사 레몬트리(이하 \"회사\"라 함)가 제공하는 전자금융거래 서비스 및 관련 서비스(이하 \"서비스\"라 함)의 이용과 관련하여, 회사와 회원 간 또는 회원 간의 권리 및 의무 및 책임사항 및 서비스 이용조건 및 절차 등 기본적인 사항을 규정함을 목적으로 합니다.</p><p></p><h2>제2조 (용어의 정의)</h2><p>① 본 약관에서 사용하는 용어의 정의는 다음과 같습니다.</p><p>1. \"회원\"이란 본 약관에 따라 가입신청에 대한 회사의 승낙을 받아 회사가 제공하는 서비스를 이용하는 자를 말합니다.</p><p>2. \"퍼핀앱\"이란 컴퓨터 등의 정보통신설비 또는 스마트폰, 태블릿 등의 모바일 정보통신설비를 이용하여 회사가 서비스를 제공할 수 있도록 설정한 가상의 영업장 또는 서비스 공간을 말하며, 애플리케이션, 웹페이지 등을 포함합니다.</p><p>3. \"접근매체\"라 함은 전자금융거래에 있어서 거래지시를 하거나 이용자 및 거래내용의 진실성과 정확성을 확보하기 위하여 사용되는 전자금융거래법 제2조 제10호에서 정하는 수단 또는 정보로서, 전자금융거래서비스를 이용하기 위하여 회사에 등록된 암호 또는 전자금융업자에 등록된 회원번호 등 회사가 지정한 수단을 말합니다.</p>',
          isEssential:false,
          deploy_at:'2022-08-25T00:00:00',
          deploior:'유보현',
          status:'REGIVATION'
        },
        {
          id:'4',
          content:'<h1>제1장 총칙</h1><h2>제1조 (목적)</h2><p>본 약관은 주식회사 퍼핀(이하 \"회사\"라 함)가 제공하는 전자금융거래 서비스 및 관련 서비스(이하 \"서비스\"라 함)의 이용과 관련하여, 회사와 회원 간 또는 회원 간의 권리 및 의무 및 책임사항 및 서비스 이용조건 및 절차 등 기본적인 사항을 규정함을 목적으로 합니다.</p><p></p><h2>제2조 (용어의 정의)</h2><p>① 본 약관에서 사용하는 용어의 정의는 다음과 같습니다.</p><p>1. \"회원\"이란 본 약관에 따라 가입신청에 대한 회사의 승낙을 받아 회사가 제공하는 서비스를 이용하는 자를 말합니다.</p><p>2. \"퍼핀앱\"이란 컴퓨터 등의 정보통신설비 또는 스마트폰, 태블릿 등의 모바일 정보통신설비를 이용하여 회사가 서비스를 제공할 수 있도록 설정한 가상의 영업장 또는 서비스 공간을 말하며, 애플리케이션, 웹페이지 등을 포함합니다.</p><p>3. \"접근매체\"라 함은 전자금융거래에 있어서 거래지시를 하거나 이용자 및 거래내용의 진실성과 정확성을 확보하기 위하여 사용되는 전자금융거래법 제2조 제10호에서 정하는 수단 또는 정보로서, 전자금융거래서비스를 이용하기 위하여 회사에 등록된 암호 또는 전자금융업자에 등록된 회원번호 등 회사가 지정한 수단을 말합니다.</p>',
          isEssential:false,
          deploy_at:'',
          deploior:'',
          status:'TEMP'
        },
        {
          id:'3',
          content:'<h1>제1장 총칙</h1><h2>제1조 (목적)</h2><p>본 약관은 주식회사 레몬트리(이하 \"회사\"라 함)가 제공하는 전자금융거래 서비스 및 관련 서비스(이하 \"서비스\"라 함)의 이용과 관련하여, 회사와 회원 간 또는 회원 간의 권리 및 의무 및 책임사항 및 서비스 이용조건 및 절차 등 기본적인 사항을 규정함을 목적으로 합니다.</p><p></p><h2>제2조 (용어의 정의)</h2><p>① 본 약관에서 사용하는 용어의 정의는 다음과 같습니다.</p><p>1. \"회원\"이란 본 약관에 따라 가입신청에 대한 회사의 승낙을 받아 회사가 제공하는 서비스를 이용하는 자를 말합니다.</p><p>2. \"레몬트리앱\"이란 컴퓨터 등의 정보통신설비 또는 스마트폰, 태블릿 등의 모바일 정보통신설비를 이용하여 회사가 서비스를 제공할 수 있도록 설정한 가상의 영업장 또는 서비스 공간을 말하며, 애플리케이션, 웹페이지 등을 포함합니다.</p><p>3. \"접근매체\"라 함은 전자금융거래에 있어서 거래지시를 하거나 이용자 및 거래내용의 진실성과 정확성을 확보하기 위하여 사용되는 전자금융거래법 제2조 제10호에서 정하는 수단 또는 정보로서, 전자금융거래서비스를 이용하기 위하여 회사에 등록된 암호 또는 전자금융업자에 등록된 회원번호 등 회사가 지정한 수단을 말합니다.</p>',
          // content:'A1BQD',
          isEssential:false,
          deploy_at:'2022-06-30T00:00:00',
          deploior:'유보현',
          status:'DEPLOIED'
        },
        {
          id:'2',
          content:'<h1>제1장 총칙</h1><h2>제1조 (목적)</h2><p>본 약관은 주식회사 레몬트리(이하 \"회사\"라 함)가 제공하는 전자금융거래 서비스 및 관련 서비스(이하 \"서비스\"라 함)의 이용과 관련하여, 회사와 회원 간 또는 회원 간의 권리 및 의무 및 책임사항 및 서비스 이용조건 및 절차 등 기본적인 사항을 규정함을 목적으로 합니다.</p><p></p><h2>제2조 (용어의 정의)</h2><p>① 본 약관에서 사용하는 용어의 정의는 다음과 같습니다.</p><p>1. \"회원\"이란 본 약관에 따라 가입신청에 대한 회사의 승낙을 받아 회사가 제공하는 서비스를 이용하는 자를 말합니다.</p><p>2. \"레몬트리앱\"이란 컴퓨터 등의 정보통신설비 또는 스마트폰, 태블릿 등의 모바일 정보통신설비를 이용하여 회사가 서비스를 제공할 수 있도록 설정한 가상의 영업장 또는 서비스 공간을 말하며, 애플리케이션, 웹페이지 등을 포함합니다.</p>',
          isEssential:false,
          deploy_at:'2022-06-13T00:00:00',
          deploior:'유보현',
          status:'DEPLOIED'
        },
        {
          id:'1',
          content:'<h1>제1장 총칙</h1><h2>제1조 (목적)</h2><p>본 약관은 주식회사 레몬트리(이하 \"회사\"라 함)가 제공하는 전자금융거래 서비스 및 관련 서비스(이하 \"서비스\"라 함)의 이용과 관련하여, 회사와 회원 간 또는 회원 간의 권리 및 의무 및 책임사항 및 서비스 이용조건 및 절차 등 기본적인 사항을 규정함을 목적으로 합니다.</p>',
          isEssential:false,
          deploy_at:'2022-06-02T00:00:00',
          deploior:'유보현',
          status:'DEPLOIED'
        },
      ],
      PRIVITE:[
        {
          id:'5',
          content:'<h1>제1조 (처리 목적과 항목)</h1><p>1. 회사는 서비스 이용에 반드시 필요한 개인신용정보를 다음과 같은 목적을 위하여 처리합니다. 처리하는 개인신용정보는 다음의 목적 이외의 용도로는 처리되지 않으며, 처리 목적과 항목이 변경되는 경우에는 별도의 동의를 받는 등 필요한 조치를 이행할 것입니다.</p><p>2. 회사는 레몬트리 서비스 (이하 “서비스”) 이용에 필요한 최소한의 개인신용정보만을 수집하고 있습니다.</p><p>3. 처음으로 이용하는 서비스의 경우 회사는 이용자에게 필수적인 정보의 입력을 요청할 수 있으며, 회사가 이미 보유한 정보가 있다면 입력을 생략하거나 이용자를 위하여 입력편의성을 제공할 수 있습니다.</p>',
          isEssential:false,
          deploy_at:'2022-08-25T00:00:00',
          deploior:'유보현',
          status:'REGIVATION'
        },
        {
          id:'1',
          content:'<h1>제1조 (처리 목적과 항목)</h1><p>1. 회사는 서비스 이용에 반드시 필요한 개인신용정보를 다음과 같은 목적을 위하여 처리합니다. 처리하는 개인신용정보는 다음의 목적 이외의 용도로는 처리되지 않으며, 처리 목적과 항목이 변경되는 경우에는 별도의 동의를 받는 등 필요한 조치를 이행할 것입니다. </p>',
          isEssential:false,
          deploy_at:'2022-06-02T00:00:00',
          deploior:'유보현',
          status:'DEPLOIED'
        },
      ]
    };
    this.lastDepolyTermContent = resultDataList[this.model.type].find( item => item.status === 'DEPLOIED' ).content
    this.copyDataList = resultDataList[this.model.type].map(item => {
      let rObj = {
        id: item.id,
        text: `${convertIdToText(item.status,'term_status_icon')} ${convertIdToText(item.status,'term_status')} | ${item.deploy_at || '미정'}T00:00:00`,
        content: item.content
      }
      return rObj
    })
  }
  getTermDetail(id, type){
    const resultDataList = {
      SERVICE:[
        {
          id:'5',
          content:'<h1>제1장 총칙</h1><h2>제1조 (목적)</h2><p>본 약관은 주식회사 레몬트리(이하 \"회사\"라 함)가 제공하는 전자금융거래 서비스 및 관련 서비스(이하 \"서비스\"라 함)의 이용과 관련하여, 회사와 회원 간 또는 회원 간의 권리 및 의무 및 책임사항 및 서비스 이용조건 및 절차 등 기본적인 사항을 규정함을 목적으로 합니다.</p><p></p><h2>제2조 (용어의 정의)</h2><p>① 본 약관에서 사용하는 용어의 정의는 다음과 같습니다.</p><p>1. \"회원\"이란 본 약관에 따라 가입신청에 대한 회사의 승낙을 받아 회사가 제공하는 서비스를 이용하는 자를 말합니다.</p><p>2. \"퍼핀앱\"이란 컴퓨터 등의 정보통신설비 또는 스마트폰, 태블릿 등의 모바일 정보통신설비를 이용하여 회사가 서비스를 제공할 수 있도록 설정한 가상의 영업장 또는 서비스 공간을 말하며, 애플리케이션, 웹페이지 등을 포함합니다.</p><p>3. \"접근매체\"라 함은 전자금융거래에 있어서 거래지시를 하거나 이용자 및 거래내용의 진실성과 정확성을 확보하기 위하여 사용되는 전자금융거래법 제2조 제10호에서 정하는 수단 또는 정보로서, 전자금융거래서비스를 이용하기 위하여 회사에 등록된 암호 또는 전자금융업자에 등록된 회원번호 등 회사가 지정한 수단을 말합니다.</p>',
          isEssential:false,
          deploy_at:'2022-08-25T00:00:00',
          deploior:'유보현',
          status:'REGIVATION'
        },
        {
          id:'4',
          content:'<h1>제1장 총칙</h1><h2>제1조 (목적)</h2><p>본 약관은 주식회사 퍼핀(이하 \"회사\"라 함)가 제공하는 전자금융거래 서비스 및 관련 서비스(이하 \"서비스\"라 함)의 이용과 관련하여, 회사와 회원 간 또는 회원 간의 권리 및 의무 및 책임사항 및 서비스 이용조건 및 절차 등 기본적인 사항을 규정함을 목적으로 합니다.</p><p></p><h2>제2조 (용어의 정의)</h2><p>① 본 약관에서 사용하는 용어의 정의는 다음과 같습니다.</p><p>1. \"회원\"이란 본 약관에 따라 가입신청에 대한 회사의 승낙을 받아 회사가 제공하는 서비스를 이용하는 자를 말합니다.</p><p>2. \"퍼핀앱\"이란 컴퓨터 등의 정보통신설비 또는 스마트폰, 태블릿 등의 모바일 정보통신설비를 이용하여 회사가 서비스를 제공할 수 있도록 설정한 가상의 영업장 또는 서비스 공간을 말하며, 애플리케이션, 웹페이지 등을 포함합니다.</p><p>3. \"접근매체\"라 함은 전자금융거래에 있어서 거래지시를 하거나 이용자 및 거래내용의 진실성과 정확성을 확보하기 위하여 사용되는 전자금융거래법 제2조 제10호에서 정하는 수단 또는 정보로서, 전자금융거래서비스를 이용하기 위하여 회사에 등록된 암호 또는 전자금융업자에 등록된 회원번호 등 회사가 지정한 수단을 말합니다.</p>',
          isEssential:false,
          deploy_at:'',
          deploior:'',
          status:'TEMP'
        },
        {
          id:'3',
          content:'<h1>제1장 총칙</h1><h2>제1조 (목적)</h2><p>본 약관은 주식회사 레몬트리(이하 \"회사\"라 함)가 제공하는 전자금융거래 서비스 및 관련 서비스(이하 \"서비스\"라 함)의 이용과 관련하여, 회사와 회원 간 또는 회원 간의 권리 및 의무 및 책임사항 및 서비스 이용조건 및 절차 등 기본적인 사항을 규정함을 목적으로 합니다.</p><p></p><h2>제2조 (용어의 정의)</h2><p>① 본 약관에서 사용하는 용어의 정의는 다음과 같습니다.</p><p>1. \"회원\"이란 본 약관에 따라 가입신청에 대한 회사의 승낙을 받아 회사가 제공하는 서비스를 이용하는 자를 말합니다.</p><p>2. \"레몬트리앱\"이란 컴퓨터 등의 정보통신설비 또는 스마트폰, 태블릿 등의 모바일 정보통신설비를 이용하여 회사가 서비스를 제공할 수 있도록 설정한 가상의 영업장 또는 서비스 공간을 말하며, 애플리케이션, 웹페이지 등을 포함합니다.</p><p>3. \"접근매체\"라 함은 전자금융거래에 있어서 거래지시를 하거나 이용자 및 거래내용의 진실성과 정확성을 확보하기 위하여 사용되는 전자금융거래법 제2조 제10호에서 정하는 수단 또는 정보로서, 전자금융거래서비스를 이용하기 위하여 회사에 등록된 암호 또는 전자금융업자에 등록된 회원번호 등 회사가 지정한 수단을 말합니다.</p>',
          isEssential:false,
          deploy_at:'2022-06-30T00:00:00',
          deploior:'유보현',
          status:'DEPLOIED'
        },
        {
          id:'2',
          content:'<h1>제1장 총칙</h1><h2>제1조 (목적)</h2><p>본 약관은 주식회사 레몬트리(이하 \"회사\"라 함)가 제공하는 전자금융거래 서비스 및 관련 서비스(이하 \"서비스\"라 함)의 이용과 관련하여, 회사와 회원 간 또는 회원 간의 권리 및 의무 및 책임사항 및 서비스 이용조건 및 절차 등 기본적인 사항을 규정함을 목적으로 합니다.</p><p></p><h2>제2조 (용어의 정의)</h2><p>① 본 약관에서 사용하는 용어의 정의는 다음과 같습니다.</p><p>1. \"회원\"이란 본 약관에 따라 가입신청에 대한 회사의 승낙을 받아 회사가 제공하는 서비스를 이용하는 자를 말합니다.</p><p>2. \"레몬트리앱\"이란 컴퓨터 등의 정보통신설비 또는 스마트폰, 태블릿 등의 모바일 정보통신설비를 이용하여 회사가 서비스를 제공할 수 있도록 설정한 가상의 영업장 또는 서비스 공간을 말하며, 애플리케이션, 웹페이지 등을 포함합니다.</p>',
          isEssential:false,
          deploy_at:'2022-06-13T00:00:00',
          deploior:'유보현',
          status:'DEPLOIED'
        },
        {
          id:'1',
          content:'<h1>제1장 총칙</h1><h2>제1조 (목적)</h2><p>본 약관은 주식회사 레몬트리(이하 \"회사\"라 함)가 제공하는 전자금융거래 서비스 및 관련 서비스(이하 \"서비스\"라 함)의 이용과 관련하여, 회사와 회원 간 또는 회원 간의 권리 및 의무 및 책임사항 및 서비스 이용조건 및 절차 등 기본적인 사항을 규정함을 목적으로 합니다.</p>',
          isEssential:false,
          deploy_at:'2022-06-02T00:00:00',
          deploior:'유보현',
          status:'DEPLOIED'
        },
      ],
      PRIVITE:[
        {
          id:'5',
          content:'<h1>제1조 (처리 목적과 항목)</h1><p>1. 회사는 서비스 이용에 반드시 필요한 개인신용정보를 다음과 같은 목적을 위하여 처리합니다. 처리하는 개인신용정보는 다음의 목적 이외의 용도로는 처리되지 않으며, 처리 목적과 항목이 변경되는 경우에는 별도의 동의를 받는 등 필요한 조치를 이행할 것입니다.</p><p>2. 회사는 레몬트리 서비스 (이하 “서비스”) 이용에 필요한 최소한의 개인신용정보만을 수집하고 있습니다.</p><p>3. 처음으로 이용하는 서비스의 경우 회사는 이용자에게 필수적인 정보의 입력을 요청할 수 있으며, 회사가 이미 보유한 정보가 있다면 입력을 생략하거나 이용자를 위하여 입력편의성을 제공할 수 있습니다.</p>',
          isEssential:false,
          deploy_at:'2022-08-25T00:00:00',
          deploior:'유보현',
          status:'REGIVATION'
        },
        {
          id:'1',
          content:'<h1>제1조 (처리 목적과 항목)</h1><p>1. 회사는 서비스 이용에 반드시 필요한 개인신용정보를 다음과 같은 목적을 위하여 처리합니다. 처리하는 개인신용정보는 다음의 목적 이외의 용도로는 처리되지 않으며, 처리 목적과 항목이 변경되는 경우에는 별도의 동의를 받는 등 필요한 조치를 이행할 것입니다.</p>',
          isEssential:false,
          deploy_at:'2022-06-02T00:00:00',
          deploior:'유보현',
          status:'DEPLOIED'
        },
      ]
    };
    const resultData = resultDataList[type].find(item => item.id === id)
    this.model.setData(resultData);
    if(this.model.deploy_at === ''){
      this.setDeployAtTomorrow();
    }
  }
}